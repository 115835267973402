import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { Box } from '.'

const StyledBox = styled(Box)`
  opacity: 0;
  transform: translateY(5vh);
  transition: opacity 0.5s ease-out, transform 750ms ease-out;
  visibility: hidden;
  will-change: opacity, visibility;

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      transform: none;
      visibility: visible;
    `}
`

export default function FadeInBox({ children, ...rest }) {
  const [isVisible, setIsVisible] = useState(false)
  const boxRef = useRef(null)

  useEffect(() => {
    const { current } = boxRef
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(entry.isIntersecting)
        }
      })
    })

    observer.observe(current)

    return () => {
      if (!current) {
        return
      }
      observer.unobserve(current)
    }
  }, [])

  return (
    <StyledBox ref={boxRef} isVisible={isVisible} {...rest}>
      {children}
    </StyledBox>
  )
}

FadeInBox.propTypes = {
  children: PropTypes.node.isRequired,
}
