import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Turn as Hamburger } from 'hamburger-react'
import { Box } from './styled-components'
import MenuModal from './MenuModal'
import { theme } from './Theme'

const HeaderContainer = styled.header`
  @media screen and (max-width: 768px) {
    background: white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
  }
`

const Nav = styled.nav`
  align-items: center;
  background: ${({ darkMode }) => (darkMode ? 'rgba(75,4,81,1)' : '')};
  border-bottom: ${({ hideNavBorder }) => (hideNavBorder ? '' : '1px solid')};
  border-color: ${({ darkMode, theme }) =>
    darkMode ? theme.colors.white : theme.colors.grey};
  color: ${({ isShowingMenu, theme }) => isShowingMenu && theme.colors.white};
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;
  transition: background 0.5s;
`

const Title = styled.div`
  color: ${({ isDarkMode }) =>
    isDarkMode ? theme.colors.white : theme.colors.grey};
  text-transform: uppercase;
`

const Header = ({ darkMode, hideNavBorder, title }) => {
  const [isShowingMenu, setIsShowingMenu] = useState(false)

  const handleOnClick = () => {
    setIsShowingMenu((isShowingMenu) => {
      return !isShowingMenu
    })
  }

  useEffect(() => {
    const onKeyDown = ({ keyCode }) => {
      if (keyCode === 77) {
        setIsShowingMenu(true)
      } else if (keyCode === 27) {
        setIsShowingMenu(false)
      }
    }

    document.addEventListener('keydown', onKeyDown)

    return () => document.removeEventListener('keydown', onKeyDown)
  }, [])

  useEffect(() => {
    if (isShowingMenu) {
      document.querySelector('body').style.overflow = 'hidden'
    } else {
      document.querySelector('body').style.overflow = ''
    }
  }, [isShowingMenu])

  let hamburgerColor

  if (isShowingMenu || darkMode) {
    hamburgerColor = theme.colors.white
  } else {
    hamburgerColor = theme.colors.black
  }

  return (
    <HeaderContainer>
      {isShowingMenu && <MenuModal onClose={() => setIsShowingMenu(false)} />}
      <Nav
        darkMode={darkMode}
        hideNavBorder={hideNavBorder}
        isShowingMenu={isShowingMenu}
      >
        <Title isDarkMode={darkMode}>{title}</Title>

        <Box zIndex={1000}>
          <Hamburger
            color={hamburgerColor}
            duration={0.55}
            label="Show menu"
            toggled={isShowingMenu}
            toggle={handleOnClick}
          />
        </Box>
      </Nav>
    </HeaderContainer>
  )
}

Header.propTypes = {
  darkMode: PropTypes.bool,
  hideNavBorder: PropTypes.bool,
  title: PropTypes.string,
}

Header.defaultProps = {
  darkMode: false,
  hideNavBorder: false,
  title: undefined,
}

export default Header
