import React from 'react'
import { Link } from 'gatsby'
import { Box, Heading, Layout, Typography } from 'src/components'

const Timeline = () => (
  <Layout title="Timeline">
    <Heading>Timeline of Events</Heading>

    <Box textAlign="center" maxWidth={480} margin="auto">
      <Typography
        fontFamily="Abril Fatface"
        fontWeight="bold"
        fontSize="28px"
        mb={6}
      >
        Saturday, October 9th
      </Typography>

      <Box mb={8}>
        <Typography fontWeight="bold" fontSize="20px" mb={3}>
          6:30pm
        </Typography>
        <Typography>What: Welcome Drinks</Typography>
        <Typography>Where: Strong Rope Red Hook</Typography>
        <Typography>185 Van Dyke St, Brooklyn, NY 11231</Typography>
        <Typography>
          Notes: Open bar and heavy appetizers, no formal dinner will be served.
          This is a dress up party in the theme of “80s Glam” - please reference
          the <Link to="/saturday/">Saturday page</Link> for costume inspiration
          and more details.
        </Typography>
      </Box>

      <Box>
        <Typography
          fontFamily="Abril Fatface"
          fontWeight="bold"
          fontSize="28px"
          mt={12}
          mb={6}
        >
          Sunday, October 10th
        </Typography>
      </Box>

      <Box mb={8}>
        <Typography fontWeight="bold" fontSize="20px" mb={3}>
          8am - 3pm
        </Typography>
        <Typography>What: Getting Ready</Typography>
        <Typography>Where: The Ludlow Hotel, 18th Floor</Typography>
        <Typography>180 Ludlow Street, New York, NY 10002</Typography>
        <Typography>
          Notes: This is open to all guests. We will have all rooms on the 18th
          floor as an “open house” style for guests to stop by, hang out or get
          ready. There will be healthy food and juices provided for breakfast
          and lunch in a casual family style. If you would like your hair and
          makeup done professionally, please RSVP which services you would like
          to Regan (reganjlee@gmail.com). A schedule of hair and makeup shifts
          will be provided the week leading up to the wedding.
        </Typography>
      </Box>

      <Box mb={8}>
        <Typography fontWeight="bold" fontSize="20px" mb={3}>
          3:45pm
        </Typography>
        <Typography>What: Party Buses Depart the Ludlow Hotel</Typography>
        <Typography>
          Where: Meet in the Ludlow Hotel lobby for further directions
        </Typography>
        <Typography>
          Notes: There will be enough room on three party buses to fit all
          guests, please make sure you are in the lobby waiting to load on the
          buses no later than 3:45pm.
        </Typography>
      </Box>

      <Box mb={8}>
        <Typography fontWeight="bold" fontSize="20px" mb={3}>
          4:30pm
        </Typography>
        <Typography>What: Guest Arrival to Prospect Park</Typography>
        <Typography>
          Where: Drop off at the intersection of Ocean Ave and Lincoln Road
        </Typography>
        <Typography>
          Notes: Please reference the physical map and instructions that came in
          the invitation suite.
        </Typography>
      </Box>

      <Box mb={8}>
        <Typography fontWeight="bold" fontSize="20px" mb={2}>
          5:00pm
        </Typography>
        <Typography>Ceremony Begins</Typography>
      </Box>

      <Box mb={8}>
        <Typography fontWeight="bold" fontSize="20px" mb={2}>
          5:30pm
        </Typography>
        <Typography>Ceremony Ends + Family Photos</Typography>
      </Box>

      <Box mb={8}>
        <Typography fontWeight="bold" fontSize="20px" mb={2}>
          5:30-7:00pm
        </Typography>
        <Typography>Cocktail Hour</Typography>
      </Box>

      <Box mb={8}>
        <Typography fontWeight="bold" fontSize="20px" mb={2}>
          7:00pm
        </Typography>
        <Typography>Seated Dinner</Typography>
      </Box>

      <Box mb={8}>
        <Typography fontWeight="bold" fontSize="20px" mb={2}>
          8:00pm
        </Typography>
        <Typography>Dancing</Typography>
      </Box>

      <Box mb={8}>
        <Typography fontWeight="bold" fontSize="20px" mb={2}>
          10:00pm
        </Typography>
        <Typography>
          Guests depart boathouse, load onto buses at Ocean Ave and Lincoln Road
          to transport to the Ludlow Hotel.
        </Typography>
      </Box>

      <Box mb={8}>
        <Typography fontWeight="bold" fontSize="20px" mb={2}>
          10:45pm
        </Typography>
        <Typography>Afterparty at the Ludlow Hotel lounge</Typography>
      </Box>
    </Box>
  </Layout>
)

export default Timeline
