import React, { useEffect, useState } from 'react'
import { Link, navigate } from 'gatsby'
import styled, { css, keyframes } from 'styled-components'

const fadeInBackground = keyframes`
  100% {
    background: rgba(0, 0, 0, 1);
  }
`

const fadeAndSlideIn = keyframes`
  100% {
    margin-right: 0px;
    opacity: 1;
  }
`

const fadeAndSlideOut = keyframes`
  0% {
    margin-right: 0px;
    opacity: 1;
  }
  100% {
    margin-right: 15px;
    opacity: 0;
  }
`

const ModalContainer = styled.nav`
  align-items: center;
  animation: ${fadeInBackground} 0.5s forwards;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  justify-content: center;
  right: 0;
  top: 0;
  z-index: 100;
`

const StyledLink = styled(Link)`
  animation: ${fadeAndSlideIn} 0.5s forwards;
  color: ${({ $isDesired, theme }) =>
    $isDesired ? theme.colors.grey : theme.colors.white};
  display: block;
  font-weight: 400;
  font-size: 2em;
  margin-right: 15px;
  margin-bottom: 32px;
  opacity: 0;
  padding: 8px;
  text-align: center;
  text-decoration: ${({ $isActive }) => ($isActive ? 'underline' : 'none')};
  text-transform: uppercase;

  ${({ $isClosing }) => {
    if (!$isClosing) {
      return css`
        &:first-of-type {
          animation-delay: 100ms;
        }

        &:nth-of-type(2) {
          animation-delay: 325ms;
        }

        &:nth-of-type(3) {
          animation-delay: 575ms;
        }

        &:nth-of-type(4) {
          animation-delay: 825ms;
        }

        &:nth-of-type(5) {
          animation-delay: 950ms;
        }
      `
    }
  }}

  ${({ isClosing }) =>
    isClosing &&
    css`
      animation: ${fadeAndSlideOut} 0.5s forwards;
    `};
`

const ROUTES = {
  home: {
    label: 'Home',
    path: '/',
  },
  saturday: {
    label: 'Saturday',
    path: '/saturday/',
  },
  sunday: {
    label: 'Sunday',
    path: '/sunday/',
  },
  timeline: {
    label: 'Timeline',
    path: '/timeline/',
  },
  faq: {
    label: 'FAQ',
    path: '/faq/',
  },
}

const MenuNavLink = ({ isActive, isClosing, isDesired, onClick, route }) => (
  <StyledLink
    $isActive={isActive}
    $isClosing={isClosing}
    $isDesired={isDesired}
    onClick={onClick}
    to={route.path}
  >
    {route.label}
  </StyledLink>
)

const MenuModal = ({ onClose }) => {
  const [isClosing, setIsClosing] = useState(false)
  const [activeRoute, setActiveRoute] = useState()
  const [desiredRoute, setDesiredRoute] = useState()
  const handleOnClick = () => {
    setIsClosing(true)
  }

  useEffect(() => {
    const handleKeyDown = ({ keyCode }) => {
      const routes = Object.keys(ROUTES)
      const currentRouteIndex = routes.indexOf(desiredRoute)

      if (keyCode === 38) {
        const nextIndex =
          currentRouteIndex === 0 ? routes.length - 1 : currentRouteIndex - 1

        setDesiredRoute(routes[nextIndex])
      } else if (keyCode === 40) {
        const nextIndex =
          currentRouteIndex === routes.length - 1 ? 0 : currentRouteIndex + 1

        setDesiredRoute(routes[nextIndex])
      } else if (keyCode === 13) {
        navigate(ROUTES[desiredRoute].path)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [desiredRoute])

  useEffect(() => {
    const activeRoute = Object.keys(ROUTES).find((route) => {
      return ROUTES[route].path === window.location.pathname
    })

    setActiveRoute(activeRoute)
    setDesiredRoute(activeRoute)
  }, [])

  useEffect(() => {
    if (isClosing) {
      setTimeout(onClose, 500)
    }
  }, [isClosing, onClose])

  return (
    <ModalContainer>
      {Object.keys(ROUTES).map((route) => (
        <MenuNavLink
          isActive={route === activeRoute}
          isDesired={route === desiredRoute}
          isClosing={isClosing}
          key={route.path}
          onClick={handleOnClick}
          route={ROUTES[route]}
        />
      ))}
    </ModalContainer>
  )
}

export default MenuModal
