import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Heading, Layout, Link, Typography } from 'src/components'

const Friday = () => {
  const data = useStaticQuery(graphql`
    query {
      sectionOne: file(relativePath: { eq: "section1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      sectionTwo: file(relativePath: { eq: "section2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      sectionThree: file(relativePath: { eq: "section3.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      sectionFour: file(relativePath: { eq: "section4.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      adamAndTheAnts: file(relativePath: { eq: "adam_and_the_ants.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      duranDuran: file(relativePath: { eq: "duran_duran.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      stuartAndBill: file(relativePath: { eq: "stuart_and_bill.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Layout title="Saturday" darkMode>
      <Heading isAlien>Welcome Drinks</Heading>

      <Box textAlign="center" color="white" maxWidth={480} mx="auto" mb={10}>
        <Box mb={4}>
          <Typography fontFamily="Neon Tubes" fontSize="20px">
            Saturday, October 9th, 2021
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography fontFamily="Neon Tubes" fontSize="20px">
            6:30pm
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography fontFamily="Neon Tubes" fontSize="20px">
            <Link darkMode href="https://goo.gl/maps/RtJ6zTkQ5Uo2Peep9">
              Strong Rope Red Hook
            </Link>
          </Typography>
          <Typography fontFamily="Neon Tubes">185 Van Dyke St.</Typography>
          <Typography fontFamily="Neon Tubes">Brooklyn, NY 11231</Typography>
        </Box>

        <Box mb={4}>
          <Typography fontFamily="Neon Tubes">
            Walk down the Barnell Street alleyway towards the water - will be at
            the end on the right.
          </Typography>
        </Box>

        <Box mb={8}>
          <Typography fontFamily="Neon Tubes" fontSize="20px">
            Open Beer, Wine + Spirts Bar
            <br />
            &
            <br />
            Heavy Appetizers
          </Typography>
        </Box>

        <Box>
          <Typography fontSize="36px" fontFamily="Neon Tubes" mb={2}>
            80's Glam
          </Typography>
          <Typography fontSize="20px" fontFamily="Neon Tubes">
            Dress to Impress
          </Typography>
        </Box>
      </Box>

      <Box margin="auto" maxWidth="1256px">
        <GatsbyImage image={data.sectionOne.childImageSharp.gatsbyImageData} />
        <Box mb={['5px', '5px', '18px']} />
        <GatsbyImage image={data.sectionTwo.childImageSharp.gatsbyImageData} />
        <Box mb={['5px', '5px', '18px']} />
        <GatsbyImage
          image={data.adamAndTheAnts.childImageSharp.gatsbyImageData}
        />
        <Box mb={['5px', '5px', '18px']} />
        <GatsbyImage
          image={data.sectionThree.childImageSharp.gatsbyImageData}
        />
        <Box mb={['5px', '5px', '18px']} />
        <GatsbyImage image={data.duranDuran.childImageSharp.gatsbyImageData} />
        <Box mb={['5px', '5px', '18px']} />
        <GatsbyImage image={data.sectionFour.childImageSharp.gatsbyImageData} />
        <Box mb={['5px', '5px', '18px']} />
        <GatsbyImage
          image={data.stuartAndBill.childImageSharp.gatsbyImageData}
        />
      </Box>
    </Layout>
  )
}

export default Friday
