import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import { Box, Heading, Typography } from '../components/styled-components'

const Saturday = ({ data }) => {
  const boathouseImage = getImage(data.boathouse)

  return (
    <Layout title="Sunday">
      <Heading>Wedding</Heading>

      <Box textAlign="center">
        <Box mb={2}>
          <Typography>Together with their families</Typography>
        </Box>

        <Box mb={2}>
          <Typography fontStyle="italic" fontSize="20px" fontWeight="bold">
            Regan Jules Lee
          </Typography>

          <Typography my={2}>and</Typography>

          <Typography fontStyle="italic" fontSize="20px" fontWeight="bold">
            Chandler Jon Moisen II
          </Typography>
        </Box>

        <Box mb={8}>
          <Typography>request the honor of your company</Typography>
          <Typography>at the celebration of their marriage</Typography>
        </Box>

        <Box mb={8}>
          <Typography>Sunday, the Tenth of October</Typography>
          <Typography>Two Thousand and Twenty One</Typography>
          <Typography>at Five o'clock in the evening</Typography>
        </Box>

        <Box mb={5}>
          <Typography>
            <a href="https://goo.gl/maps/29Hc1dNeHTGDK4Bf9">
              Prospect Park Boathouse
            </a>
          </Typography>
          <Typography>Brooklyn, New York</Typography>
        </Box>

        <Box mb={5}>
          <Typography>Dinner and dancing to follow</Typography>
          <Typography>Black Tie</Typography>
        </Box>

        <Typography mb={8}>
          The favor of a reply is requested by September 1st
        </Typography>

        <GatsbyImage image={boathouseImage} />

        <Box mt={9} maxWidth={480} mx="auto">
          <Typography fontSize="underline" mb={2} fontWeight="bold">
            Important Note
          </Typography>
          <Typography textAlign="justify" lineHeight={1.25}>
            Please schedule your transportation in advance and plan to arrive at
            the park no later than 4:30pm. If coming from The Ludlow Hotel,
            there will be party buses to transport all guests to the correct
            drop off point on the edge of Prospect Park. The party buses will
            depart promptly at 3:45pm. Vehicles are not able to pull directly up
            to the boathouse and guests will have to navigate a 5 minute walk
            through the park. Wayfinders will be stationed along the route to
            help guide the way. It took even us Brooklyn experts a while to find
            the Boathouse the first time we went. The ceremony will start
            promptly at 5pm. Please bring the map + directions sent with the
            physical invitation with you on the day of the wedding for
            reference.
          </Typography>
        </Box>
      </Box>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    boathouse: file(relativePath: { eq: "boathouse.png" }) {
      childImageSharp {
        gatsbyImageData(width: 480)
      }
    }
  }
`

export default Saturday
