import styled, { css, keyframes } from 'styled-components'
import {
  color,
  grid,
  flexbox,
  layout,
  position,
  space,
  typography,
} from 'styled-system'

export const Box = styled.div`
  ${color}
  ${flexbox}
  ${grid}
  ${layout}
  ${position}
  ${space}
  ${typography}
`

const slideIn = keyframes`
  from {
    margin-top: 0.5em;
  }

  to {
    margin-top: 0;
  }
`

const glow = keyframes`
  to {
      text-shadow: -2px 4px 0px #E939AB,
        -2px -2px 10px #E939AB;
  }
`

export const Heading = styled.h1`
  animation: ${slideIn} 250ms ease-in-out;
  font-size: 3.5em;
  letter-spacing: 3px;
  margin-top: 0;
  margin-bottom: 64px;
  text-align: center;

  ${({ isAlien }) =>
    isAlien &&
    css`
      animation: ${glow} 750ms infinite alternate;
      font-family: 'Alien Encounters';
      font-weight: 400;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: ${({ theme }) => theme.colors.pink};
      color: white;
      font-size: 5em;
      text-shadow: -2px 4px 20px ${({ theme }) => theme.colors.pink},
        -2px -2px 10px ${({ theme }) => theme.colors.pink};

      @media screen and (max-width: 500px) {
        font-size: 3.5em;
      }
    `}
`

export const Typography = styled.p`
  margin: 0;
  margin-bottom: 8px;
  ${color}
  ${layout}
  ${space}
  ${typography}
`

export const Block = styled.div`
  margin-bottom: 28px;
}`

export const Link = styled.a`
  color: ${({ darkMode, theme }) => (darkMode ? theme.colors.white : '#000')};
  display: inline-block;
  font-weight: bold;
  text-decoration: none;

  &::after {
    content: '';
    width: 100%;
    height: 1px;
    display: block;
    background: ${({ darkMode, theme }) =>
      darkMode ? theme.colors.white : '#000'};
    transition: 300ms;
  }

  &:hover::after {
    width: 0;
  }
`

export const Image = styled.img`
  ${layout}
  ${position}
`
