import '../css/typography.css'
import React from 'react'
import PropTypes from 'prop-types'
import styled, { createGlobalStyle } from 'styled-components'
import SEO from './seo'
import Theme from './Theme'
import Header from './Header'

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    font: 400 16px 'Courier Prime', serif;
    margin: 0;
    color: #353839;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: 'Abril Fatface';
  }

  p {
    margin: 0;
    margin-bottom: 6px;
  }

  a {
    color: #000;
    display: inline-block;
    font-weight: bold;
    text-decoration: none;
    
    &::after {
      content: '';
      width: 100%;
      height: 1px;
      display: block;
      background: black;
      transition: 300ms;
    }

    &:hover::after {
      width: 0;
    }
  }

  @media screen and (max-width: 768px) {
    body {
      margin-top: 69px;
    }
  }
`

const Main = styled.main`
  background: ${({ darkMode }) =>
    darkMode
      ? 'linear-gradient(180deg, rgba(75,4,81,1) 0%, rgba(72,20,78,1) 18%, rgba(68,33,88,1) 42%, rgba(63,77,108,1) 100%)'
      : ''};
  padding: 32px 16px;
`

const Layout = ({ children, darkMode, hideNavBorder, title }) => (
  <Theme>
    <GlobalStyles />
    <SEO title={title} />
    <Header title={title} darkMode={darkMode} hideNavBorder={hideNavBorder} />
    <Main darkMode={darkMode}>{children}</Main>
  </Theme>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  darkMode: PropTypes.bool,
  hideNavBorder: PropTypes.bool,
  title: PropTypes.string,
}

Layout.defaultProps = {
  darkMode: false,
  hideNavBorder: false,
  title: undefined,
}

export default Layout
