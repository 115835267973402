import React from 'react'
import { ThemeProvider } from 'styled-components'

export const theme = {
  colors: {
    black: '#353839',
    pink: '#E939AB',
    midnightPurple: '#393960',
    grey: '#999696',
    white: '#FFF',
  },
  space: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96],
}

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

export default Theme
