import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Box, Heading, Layout, Typography } from 'src/components'

const Question = styled(Typography)`
  font-size: 20px;
  font-weight: bold;
`

const Answer = styled(Typography)`
  margin-bottom: 64px;
`

const FAQ = () => (
  <Layout title="FAQ">
    <Heading>FAQ</Heading>

    <Box maxWidth={760} margin="auto">
      <Question>What airport should I fly into?</Question>
      <Answer>
        We suggest that you either fly into LGA (Laguardia) or JFK (John F.
        Kennedy) airports. LGA has recently undergone many renovations and it is
        much better than it used to be. LGA is the closest airport to The Ludlow
        hotel, about a 25 minute drive. JFK is a much larger airport and has
        always been an easy airport to navigate. JFK is a little bit further out
        than LGA, it is about a 45 minute drive to The Ludlow hotel. We don't
        recommend flying into EWR (Newark, New Jersey) as much because it can be
        about an hour drive to get to The Ludlow Hotel.
      </Answer>

      <Question>
        What is the best method of transportation from the airports to The
        Ludlow Hotel?
      </Question>
      <Answer>
        We recommend calling an Uber or Lyft or the second option would be
        waiting in the Taxi line. We prefer Uber or Lyft for longer distances,
        such as going to and from the airports, because the cost is locked in
        from the beginning. In a taxi, if you run into a lot of traffic, the
        meter keeps going up. There are public transportation options but they
        require many transfers and it is not easy to navigate.
      </Answer>

      <Question>
        What is the best way to get around the city and to and from each event
        for the wedding?
      </Question>
      <Typography>
        If you are going less than 10 - 15 blocks, we recommend walking. If you
        are a brisk walker you can estimate a minute a block. If you need a
        really quick transport to a destination not too far away, and you are
        close to a main north/south avenue, we recommend flagging down a cab. If
        you are going further distances we recommend calling an Uber or Lyft.
        Make sure to double check your pickup spot in the app because in a dense
        city like New York it can jump around to other blocks near you and
        default to where your phone "thinks" you are. If you want to try out the
        NYC Subway system here are some tips:
      </Typography>
      <Box mb={8}>
        <ul>
          <li>Metrocards are sold at kiosks at every subway station</li>
          <li>
            You can purchase a three or seven-day unlimited pass depending on
            the length of your stay if you plan to use the Subway a lot
          </li>
          <li>
            If you have ApplePay set up on your phone, you can hold your phone
            screen up to the turnstile screens for automatic payment
          </li>
          <li>Green globes mark subway entrances</li>
          <li>Use the Google Maps app to navigate the system</li>
          <li>Pay special attention to the direction the train is headed</li>
          <li>
            Double-check you are entering on the right side before swiping your
            card as there is a time-limit to swipe it again
          </li>
          <li>If you are lost, most people will gladly help</li>
          <li>Don't stand in anyone's way</li>
        </ul>
      </Box>

      <Question>Will any transportation be provided?</Question>
      <Answer>
        Party buses will be transporting guests on the day of the wedding
        (October, 10th 2021) from The Ludlow Hotel to the edge of Prospect Park.
        Guests will need to walk 5 minutes into the park to The Prospect Park
        Boathouse where the wedding ceremony and reception will take place. The
        same party buses will then transport guests back to The Ludlow Hotel
        after the reception has concluded. Please refer to the physical map and
        instructions that came with your invitation.
      </Answer>

      <Question>
        Can I stay at The Ludlow Hotel if I haven't yet made a reservation?
      </Question>
      <Answer>
        Our wedding room block is full, but they may still have a few rooms
        remaining at a higher rate per night. They were only able to extend a
        certain number of rooms at a discounted rate for our wedding room block.
      </Answer>

      <Question>
        When should we plan to arrive and depart New York City?
      </Question>
      <Answer>
        The events will start Saturday morning, October 9th and conclude late in
        the evening on Sunday, October 10th. We recommend arriving by Friday
        evening, October 8th and departing no earlier than Monday morning,
        October 11th.
      </Answer>

      <Question>What should we wear?</Question>
      <Answer>
        The attire for the wedding ceremony and reception is Black Tie. This
        means tuxedos for men and long gowns for women. Please do not wear a
        casual suit or a dress at or above the knees. For the Welcome Drinks
        party it is an "80s Glam" dress up theme. Please refer to the{' '}
        <Link to="/saturday/">Saturday</Link> page for outfit inspiration.
      </Answer>

      <Question>What will the weather be like this time of year?</Question>
      <Answer>
        The weather is very pleasant in New York and Brooklyn in early October.
        The high is usually around 70 degrees and the low is usually around 55
        degrees. The warmest time of day will be between 2 - 6pm and the sunset
        is at 6:24pm. It will be moderately humid and the chance of rain is
        lower this time of year. I would recommend a light pashmina/wrap if you
        are wearing a dress that you think you might become cold in after the
        sun goes down. We will be indoors from 7pm onward. The Welcome Drinks
        party on Saturday evening is mostly indoors, with the option to go
        outside as well.
      </Answer>

      <Question>May I bring a date?</Question>
      <Answer>
        In order to keep the number of guests to a minimum, we are only able to
        accommodate those guests formally invited on your wedding invitation. If
        you have a question or need any clarification of who this does or does
        not include, please reach out to Regan.
      </Answer>

      <Question>Are kids welcome?</Question>
      <Answer>
        In order to keep the number of guests to a minimum and to allow all
        guests to enjoy themselves fully, we have chosen to not allow kids at
        any of the events during the wedding weekend. Thank you for
        understanding.
      </Answer>

      <Question>May I join in getting ready on the wedding day?</Question>
      <Answer>
        YES! We will have the entire 18th floor open to all guests to stop by,
        hang out, and join in getting ready. There will be light food and
        refreshments provided throughout the day for all guests to enjoy. If you
        would like professional hair and makeup done, please contact Regan by
        September 1st to let her know which service you would like.
      </Answer>

      <Question>May I make song requests to the DJs?</Question>
      <Answer>
        Only if your name is Britt Williams.
        <br />
        <br />
        We have worked really hard to curate the perfect set of music that will
        be played by each DJ. Please do not make song requests to the DJ at
        either event. If you would like certain songs played, please contact
        Regan by September 1st to make that request and it will be considered.
      </Answer>

      <Question>Will you be having an afterparty?</Question>
      <Answer>
        After the wedding we plan to party and enjoy further cocktails in the
        lobby lounge at the Ludlow Hotel. All guests are welcome to join in.
      </Answer>

      <Question>
        When is the RSVP deadline? Do I need to mail back my reply card?
      </Question>
      <Answer>
        The RSVP deadline is September 1st. Please mail back the reply card and
        include dietary restrictions on the prompted line. If you cannot mail
        back the reply card on time for some reason, please communicate your
        RSVP status to Regan by September 1st either via email -{' '}
        <b>reganjlee@gmail.com</b> - or via phone <b>720.333.9185</b>.
      </Answer>

      <Question>
        May I take and post pictures of the wedding on social media platforms?
      </Question>
      <Answer>
        We encourage you to be present in the special moments we will share
        together and stay off of your phones as much as possible, especially
        during the wedding ceremony. Our photographer will be capturing all of
        the special moments during both Saturday's and Sunday's events. If you
        would like to take additional photos, please be mindful that the use of
        your phone is not interfering with our photographers efforts. You can
        share photos to social media after the weekend has concluded - we do not
        have a hashtag.
      </Answer>

      <Question>Where are you registered? </Question>
      <Answer>
        Please see our registry, linked{' '}
        <a
          href="https://www.zola.com/registry/reganandchandler"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </Answer>

      <Question>
        Is there anything I need to be aware of regarding COVID-19 protocols?
      </Question>
      <Answer>
        New York City is now requiring proof of full vaccination to enter bars,
        restaurants, event venues, etc. A negative PCR or rapid test is no
        longer an option. Please text or email a photo of your vaccination card
        to Regan at your earliest convenience. We will be compiling them all
        into an organized format to submit to each venue that events are held at
        during the wedding weekend.
      </Answer>

      <Question>
        I still have questions, what is the best way to contact you?
      </Question>
      <Answer>
        Please contact Regan at her cell: <b>720.333.9185</b> or her email:
        <b>reganjlee@gmail.com</b>
      </Answer>
    </Box>
  </Layout>
)

export default FAQ
