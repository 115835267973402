import React from 'react'
import styled, { keyframes } from 'styled-components'
import Layout from '../components/Layout'
import { Box } from '../components/styled-components'
import skyline from '../images/skyline.svg'

const DateContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media screen and (min-width: 425px) {
    position: absolute;
    height: calc(100% - 360px);
    width: calc(100% - 32px);
  }
`

const DateInnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: -1;

  @media screen and (min-width: 425px) {
    align-items: baseline;
    flex-direction: row;
  }
`

const fadeAndDropIn = keyframes`
  100% {
    margin-bottom: 0;
    opacity: 1;
  }
`

const fadeIn = keyframes`
  100% {
    opacity: 1;
  }
`

const DateBase = styled(Box)`
  animation: 1.5s ${fadeAndDropIn} 0.5s forwards;
  font-family: 'Abril Fatface';
  font-size: ${({ separator }) => (separator ? '72px' : '10em')};
  margin-bottom: 25px;
  opacity: 0;

  &:nth-of-type(1) {
    animation-delay: 100ms;
  }

  &:nth-of-type(2) {
    animation-delay: 125ms;
  }

  &:nth-of-type(3) {
    animation-delay: 450ms;
  }

  &:nth-of-type(4) {
    animation-delay: 475ms;
  }

  &:nth-of-type(5) {
    animation-delay: 800ms;
  }

  &:nth-of-type(6) {
    animation-delay: 1150ms;
  }

  @media screen and (max-width: 425px) {
    &:nth-of-type(1) {
      animation-delay: 100ms;
    }

    &:nth-of-type(3) {
      animation-delay: 450ms;
    }

    &:nth-of-type(5) {
      animation-delay: 800ms;
    }

    &:nth-of-type(6) {
      animation-delay: 1150ms;
    }

    display: ${({ separator }) => separator && 'none'};
    font-size: 5em;
  }
`

const Image = styled.img`
  animation: 2.5s ${fadeIn} 1150ms forwards;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  width: 100%;
`

const IndexPage = () => (
  <Layout hideNavBorder>
    <DateContainer>
      <DateInnerContainer>
        <DateBase>10</DateBase>
        <DateBase mx={2} separator>
          .
        </DateBase>
        <DateBase>10</DateBase>
        <DateBase mx={2} separator>
          .
        </DateBase>
        <DateBase>20</DateBase>
        <DateBase>21</DateBase>
      </DateInnerContainer>
    </DateContainer>

    <Image src={skyline} />
  </Layout>
)

export default IndexPage
